import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';

function StatisticsTable() {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(false);  // Set initial loading state to false
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchStatistics = async () => {
    setLoading(true);
    setError('');
    try {
      const params = new URLSearchParams({
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
      });

      const response = await axios.get(`${API_URL}/manager/read/statistics?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenManager')}`,
        },
      });
      setStatistics(response.data);
    } catch (err) {
      setError('Failed to fetch statistics: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 bg-black rounded-xl border border-gray-800">
      <h1 className="text-2xl font-bold text-center text-white mb-6">User Statistics</h1>
      <div className="flex justify-center mb-4">
        <input
          type="date"
          className="mx-2 p-2 text-black"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="mx-2 p-2 text-black"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={fetchStatistics}
        >
          Fetch Statistics
        </button>
      </div>
      {loading ? (
        <p>Loading statistics...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal bg-black border-collapse">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Username
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Route
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Total Use
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Cache Use
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Non-Cache Use
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Base Use
                </th>
              </tr>
            </thead>
            <tbody>
              {statistics.map((userStat, userIndex) => (
                <React.Fragment key={userIndex}>
                  {Object.entries(userStat.routes).map(([route, stats], routeIndex) => (
                    <tr key={route} className={`${routeIndex === 0 ? 'bg-gray-700' : 'bg-gray-800'} hover:bg-gray-600`}>
                      {routeIndex === 0 && (
                        <td rowSpan={Object.keys(userStat.routes).length} className="px-5 py-5 border-b border-gray-800 text-sm">
                          {userStat.username}
                        </td>
                      )}
                      <td className="px-5 py-5 border-b border-gray-800 text-sm">
                        {route}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-800 text-sm">
                        {stats.use}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-800 text-sm">
                        {stats.from_cache_use}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-800 text-sm">
                        {stats.not_from_cache_use}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-800 text-sm">  {/* Add this cell */}
                        {stats.from_base_use || 0}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default StatisticsTable;
