// src/components/ManagerDashboard.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersTableManager from "./UserTableManager";
import { useAutoLogoutManager } from '../utils/useAutoLogout'
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import ManagerStatisticsTable from "./ManagerStatisticTable";
import ManagerExternalStatisticsTable from "./ManagerExternalUsageTable";

// import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';


function ManagerDashboard() {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const token = localStorage.getItem('tokenManager');
  const isTokenExpired = useAutoLogoutManager(token);
  const showDashboard = () => setCurrentView('dashboard');
  const showVersion = () => setCurrentView('showVersion');

  const handleLogout = () => {
    localStorage.removeItem('tokenManager');
    navigate('/manager/login');
  };

  // Render the appropriate view based on the currentView state
  let view;
  switch (currentView) {
    case 'dashboard':
      view = (
          <>
            <div className="flex flex-row justify-between">
              <UsersTableManager  />
            </div>
            <div className="my-4 flex flex-row justify-between">
              <ManagerStatisticsTable />
            </div>
            <div className="my-4 flex flex-row justify-between">
              <ManagerExternalStatisticsTable />
            </div>
          </>
        );
    break;
    case 'showVersion':
      view = <div>Version: 0.6 of Manager UI</div>;
    break;
    default:
      view = (
        <>
          {isTokenExpired && (
            <div className="bg-red-600 text-white p-3 rounded">
              Your session has expired. Please log in again.
            </div>
          )}
        </>
      );
      break;
  }

  return (
    <div className="flex bg-gray-800 h-screen text-white">
      {/* Sidebar */}
      <aside className={`transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-black fixed inset-y-0 left-0 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0`}>
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex items-center justify-between p-4">
              <span className="text-lg font-semibold bg-clip-text">CoreAPI.Store</span>
              <button onClick={() => setSidebarOpen(false)} className="rounded-lg lg:hidden">
                <XIcon className="w-6 h-6 text-white"/>
              </button>
            </div>
            <nav className="m-4 space-y-2 mt-5">
              <button onClick={showDashboard} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                Main
              </button>

              <button onClick={showVersion} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                Version
              </button>
              {/* Other navigation links can be added here */}
            </nav>
          </div>
          <button onClick={handleLogout} className="text-center font-bold mx-2 my-4 px-4 py-4 mt-auto rounded-full hover:bg-red-700">
            Logout
          </button>
        </div>
      </aside>

      {/* Content area */}
      <div className="flex-1 flex flex-col">
        <header className="px-4 py-3 bg-gray-800 flex justify-between items-center">
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-gray-500 focus:outline-none lg:hidden">
            <MenuIcon className="w-6 h-6"/>
          </button>
          <h1 className="text-xl font-bold">Manager Dashboard</h1>
        </header>

        {/* Main content */}
        <main className="flex-1 overflow-y-auto p-4">
          {isTokenExpired && (
            <div className="bg-red-600 text-white p-3 rounded">
              Your session has expired. Please log in again.
            </div>
          )}
          {view}
          {/* Additional content here */}
        </main>
      </div>
    </div>
  );
}

export default ManagerDashboard;
