// src/components/RoutesTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';

function RoutesTable({ onEditRoute }) {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const handleEditClick = (routeId) => {
    console.log('Edit button clicked for route ID:', routeId);
    onEditRoute(routeId); // Call the callback function with the selected route ID
  };

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/read/routes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRoutes(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch routes');
        setLoading(false);
      }
    };

    fetchRoutes();
  }, []);

  return (
    <div className="container mx-auto p-4 rounded-xl border border-gray-800 bg-black">
      <h1 className="text-2xl font-bold text-center text-white mb-6 border border-gray-700 rounded-full">Routes</h1>
      {loading ? (
        <p>Loading routes...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal table-auto bg-black border-collapse">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Route Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Description
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Permissions
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {routes.map((route) => (
                <tr key={route.id}>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {route.name}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {route.description}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {route.permissions && route.permissions.length > 0 ? (
                      route.permissions.map((permission) => (
                        <span key={permission.id} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                          {permission}
                        </span>
                      ))
                    ) : (
                      <span className="text-gray-500">No Permissions</span> // Default message
                    )}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    <button
                      onClick={() => handleEditClick(route.id)}
                      className="inline-flex px-3 py-2 text-base font-light text-center text-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-black"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RoutesTable;
