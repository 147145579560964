import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import {XIcon} from "@heroicons/react/solid";

const AssociateRoutePermission = () => {
  const [userId, setUserId] = useState('');
  const [permissionName, setPermissionName] = useState('');
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const token = localStorage.getItem('token');

  const clearSuccess = () => setSuccess('');
  const clearError = () => setError('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await axios.get(`${API_URL}/admin/read/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(usersResponse.data);

        const permissionsResponse = await axios.get(`${API_URL}/admin/read/permissions`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermissions(permissionsResponse.data);
      } catch (error) {
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(
        `${API_URL}/admin/create/associate_user_permission`,
        { username: userId, permission: permissionName },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data) {
        // Check if the association already exists
        if (response.data.status === 'exists') {
          setError('Association already exists.');
        }
        // Check if the association was created successfully
        else if (response.data.status === 'created') {
          setSuccess('Association created successfully.');
        }
        // Handle other messages
        else {
          setError(response.data.message || 'An unexpected response was received.');
        }
      } else {
        setError('An unexpected error occurred.');
      }

      setUserId('');
      setPermissionName('');

    } catch (error) {
      setError(error.response?.data?.detail || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-gray-900 p-8 border border-gray-600 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-center text-white mb-6">Associate User with Permission</h2>
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          {success}
          <button onClick={clearSuccess} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-green-500" />
          </button>
        </div>
      )}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          {error}
          <button onClick={clearError} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-red-500" />
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6 text-black">
        <div className="form-group">
          <label htmlFor="user" className="text-sm font-medium text-white block mb-2">User:</label>
          <select
            id="user"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            required
          >
            {users.map((user) => (
              <option key={user.id} value={user.username}>{user.username}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="permission" className="text-sm font-medium text-white block mb-2">Permission:</label>
          <select
            id="permission"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={permissionName}
            onChange={(e) => setPermissionName(e.target.value)}
            required
          >
            {permissions.map((permission) => (
              <option key={permission.id} value={permission.name}>{permission.name}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">Associate</button>
      </form>
    </div>
  );
};

export default AssociateRoutePermission;