import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useAutoLogout } from '../utils/useAutoLogout';
import { XIcon } from '@heroicons/react/solid';

const CreatePermission = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  useAutoLogout(token, navigate);
  const [permission, setPermission] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const clearSuccess = () => setSuccess('');
  const clearError = () => setError('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`${API_URL}/admin/create/permission`, { permission }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess(response.data.message);
      setPermission('');
    } catch (error) {
      setError(error.response?.data?.detail || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-gray-900 p-8 border border-gray-600 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-center text-white mb-6">Create New Permission</h2>
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          {success}
          <button onClick={clearSuccess} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-green-500" />
          </button>
        </div>
      )}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          {error}
          <button onClick={clearError} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-red-500" />
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="form-group">
          <label htmlFor="permission" className="text-sm font-medium text-white block mb-2">Permission Name:</label>
          <input
            type="text"
            id="permission"
            className="w-full px-3 py-2 border border-gray-300 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">Create Permission</button>
      </form>
    </div>
  );
};

export default CreatePermission;
