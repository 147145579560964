// src/components/Tech.js

import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../assets/logo.svg';


const Tech = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between bg-black">
        <section className="container h-[calc(100vh-32px)] md:h-[calc(100vh-80px)] flex items-end">
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">Welcome to CoreAPI Service Management</h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">Select your role to log in.</p>
                    <Link to="/manager/login" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-black">
                        Login as Manager
                    </Link>
                    <Link to="/admin/login" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white">
                        Login as Admin
                    </Link>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                    <LogoSvg className="w-full h-full text-white" />
                </div>
            </div>
        </section>
    </div>
  );
};

export default Tech;
