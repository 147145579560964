import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { useNavigate } from "react-router-dom";
import { useAutoLogout } from "../utils/useAutoLogout";
import { XIcon } from "@heroicons/react/solid";

function UpdateUser({ userId }) {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  useAutoLogout(token, navigate);
  const [username, setUsername] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearSuccess = () => setSuccess('');
  const clearError = () => setError('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      const response = await axios.put(`${API_URL}/admin/update/user/${userId}`, { username, is_active: isActive, password }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess(response.data.message);
      // Optionally reset form here
    } catch (error) {
      setError(error.response?.data?.detail || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-gray-900 p-8 border border-gray-600 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-center text-white mb-6">Update User</h2>
      {/* Display success and error messages */}
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          {success}
          <button onClick={clearSuccess} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-green-500" />
          </button>
        </div>
      )}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          {error}
          <button onClick={clearError} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <XIcon className="w-5 h-5 text-red-500" />
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6 text-black">
        <div className="form-group">
          <label htmlFor="username" className="text-sm font-medium text-white block mb-2">Username:</label>
          <input
            type="text"
            name="username"
            id="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className="w-full p-2 border border-gray-600 rounded"
            placeholder="Leave blank to keep the same"
          />
        </div>
        <div className="form-group">
          <label htmlFor="isActive" className="text-sm font-medium text-white block mb-2">Active:</label>
          <input
            type="checkbox"
            name="isActive"
            id="isActive"
            checked={isActive}
            onChange={e => setIsActive(e.target.checked)}
            className="w-full p-2 border border-gray-600 rounded"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="text-sm font-medium text-white block mb-2">Password (optional):</label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-600 rounded"
            placeholder="Leave blank to keep the same"
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update'}
        </button>
      </form>
    </div>
  );
}

export default UpdateUser;