// src/components/NotFound.js

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const NotFound = () => {
  const [message, setMessage] = useState('');
  const fullMessage = "404, page not found.";

  useEffect(() => {
    if (message.length < fullMessage.length) {
      setTimeout(() => {
        setMessage(fullMessage.slice(0, message.length + 1));
      }, 100); // Adjust speed as needed
    }
}, [message, fullMessage]);

return (
    <div className="flex flex-col min-h-screen bg-black justify-between">
      <main className="grid h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-6xl">> {message}</h1>
          <p className="mt-6 text-base leading-7 text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
