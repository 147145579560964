// src/components/ProtectedRouteAdmin.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRouteAdmin = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    // User not logged in, redirect to login page
    return <Navigate to="/admin/login" replace />;
  }

  // User is logged in, allow access to the route
  return children;
};

export default ProtectedRouteAdmin;
