// src/utils/auth.js

import axios from 'axios';
import { API_URL } from './constants';

export const login = async (username, password) => {
  try {
    console.log(process.env.REACT_APP_API_ENDPOINT)
    const response = await axios.post(`${API_URL}/admin/token`, { username, password });
    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
    }
    return response.data;
  } catch (error) {
    console.error('Login error', error.response);
    throw error;
  }
};

export const loginManager = async (username, password) => {
  try {
    console.log(process.env.REACT_APP_API_ENDPOINT)
    const response = await axios.post(`${API_URL}/manager/token`, { username, password });
    if (response.data.access_token) {
      localStorage.setItem('tokenManager', response.data.access_token);
    }
    return response.data;
  } catch (error) {
    console.error('Login error', error.response);
    throw error;
  }
};
