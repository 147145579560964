// src/components/AdminLogin.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../utils/auth';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/admin/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login(username, password);
      navigate('/admin/dashboard');
    } catch (error) {
      setError('Invalid username or password');
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Hide the popup after 3 seconds
    }
  };

  const handleBackToMain = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-800 lg:flex-row">
      {/* Left side with title - hidden on mobile */}
      <div className="hidden lg:flex w-full lg:w-1/2 bg-black flex-col justify-center items-center text-white p-12">
        <h1 className="text-xl xl:text-6xl text-white font-bold mb-3">CoreAPI Service</h1>
        <p className="text-lg text-white">Your management tool for the DataAPI</p>
      </div>
      {/* Right side with login form - full width on mobile */}
      <div className="w-full lg:w-1/2 flex justify-center bg-gray-800 items-center p-6 lg:p-0">
        <div className="max-w-md w-full">
          <form onSubmit={handleSubmit} className="bg-white p-8 border border-black rounded-lg space-y-6">
            <h1 className="text-2xl font-bold mb-3">Admin Login</h1>
            <div>
              <label htmlFor="username" className="sr-only">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-black focus:border-black"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-black focus:border-black"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
              >
                Log in
              </button>
            </div>
            <div>
              <button
                onClick={handleBackToMain}
                className="w-full flex justify-center py-2 px-4 rounded-md text-sm font-medium text-black"
              >
                Back to Main <span aria-hidden="true">&rarr;</span>
              </button>
            </div>
          </form>
          <div className="justify-center items-center py-2">
        </div>
          {showError && (
              <div className="fixed inset-0 bg-black bg-opacity-10 flex justify-center items-center">
                <div className="bg-white p-4 rounded-md shadow">
                  <p className="text-red-500 text-center">{error}</p>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
