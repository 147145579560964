// src/components/MagicSphere.js
import React from 'react';
import styles from '../MagicSphere.module.css';
import {Link} from "react-router-dom";
import {DOCS_URL} from "../utils/constants";

const MagicSphere = () => {
  return (
      <div className={styles.fullPageBackground}>
            <div className={styles.sphere}>
                  <div className={`${styles.ring} ${styles.ring1}`}/>
                  <div className={`${styles.ring} ${styles.ring2}`}/>
                  <div className={`${styles.ring} ${styles.ring3}`}/>
                  <div className={`${styles.ring} ${styles.ring4}`}/>
                  <div className={`${styles.ring} ${styles.ring5}`}/>
                  <div className={`${styles.ring} ${styles.ring6}`}/>
                  <div className={`${styles.ring} ${styles.ring7}`}/>
                  <div className={`${styles.ring} ${styles.ring8}`}/>
                  <div className={`${styles.ring} ${styles.ring9}`}/>
                  <div className={`${styles.ring} ${styles.ring10}`}/>
                  <div className={`${styles.ring} ${styles.ring11}`}/>
                  <div className={`${styles.ring} ${styles.ring12}`}/>
                  <div className={`${styles.ring} ${styles.ring13}`}/>
                  <div className={`${styles.ring} ${styles.ring14}`}/>
                  <div className={`${styles.ring} ${styles.ring15}`}/>
                  <div className={`${styles.ring} ${styles.ring16}`}/>
                  <div className={`${styles.ring} ${styles.ring17}`}/>
                  <div className={`${styles.ring} ${styles.ring18}`}/>
                  <div className={`${styles.ring} ${styles.ring19}`}/>
                  <div className={`${styles.ring} ${styles.ring20}`}/>
                  <div className={`${styles.ring} ${styles.ring21}`}/>
                  <div className={`${styles.ring} ${styles.ring22}`}/>
                  <div className={`${styles.ring} ${styles.ring23}`}/>
                  <div className={`${styles.ring} ${styles.ring24}`}/>
                  <div className={`${styles.ring} ${styles.ring25}`}/>
                  <div className={`${styles.ring} ${styles.ring26}`}/>
                  <div className={`${styles.ring} ${styles.ring27}`}/>
                  <div className={`${styles.ring} ${styles.ring28}`}/>
                  <div className={`${styles.ring} ${styles.ring29}`}/>
                  <div className={`${styles.ring} ${styles.ring30}`}/>
                  <div className={`${styles.ring} ${styles.ring31}`}/>
                  <div className={`${styles.ring} ${styles.ring32}`}/>
                  <div className={`${styles.ring} ${styles.ring33}`}/>
                  <div className={`${styles.ring} ${styles.ring34}`}/>
                  <div className={`${styles.ring} ${styles.ring35}`}/>
                  <div className={`${styles.ring} ${styles.ring36}`}/>
            </div>
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <div className="mr-auto center lg:col-span-7">
                    <h1 className="bg-gradient-to-r from-red-600 via-orange-500 to-purple-400 inline-block text-transparent bg-clip-text max-w-2xl mb-4 text-xl font-extrabold tracking-tight leading-none md:text-xl xl:text-2xl">DataAPI.Store</h1>
                    <p className="text-gray-500">Tools that make it easier to find information.</p>
                    <a href={DOCS_URL} className="inline-flex items-left py-3 font-medium text-white">
                        Docs
                    </a>
                    <Link to="/status" className="mx-3 inline-flex items-left py-3 font-medium text-white">
                        Status
                    </Link>
                    <Link to="/tech" className="inline-flex items-left py-3 font-medium text-white">
                        Tech
                    </Link>
                </div>
            </div>
      </div>
  );
};

export default MagicSphere;
