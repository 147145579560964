import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { Link } from "react-router-dom";

const StatusPage = () => {
  const [status, setStatus] = useState('Checking...');
  const [isAvailable, setIsAvailable] = useState(null);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        await axios.get(`${API_URL}/status`); // Adjust the endpoint as necessary
        setStatus('Available');
        setIsAvailable(true);
      } catch (error) {
        setStatus('Unavailable');
        setIsAvailable(false);
      }
    };

    checkStatus();
  }, []);

  return (
    <div className="flex flex-col min-h-screen justify-between bg-black">
      <main className="grid h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-white text-2xl">> /status</p>
          <h1 className="mt-4 text-3xl font-bold text-white tracking-tight sm:text-5xl">
            Status: <span className={isAvailable ? "text-green-600" : "text-red-600"}>{status}</span>
          </h1>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm border border-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StatusPage;
