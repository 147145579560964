// src/components/AdminDashboard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutoLogout } from '../utils/useAutoLogout'
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import CreateUser from '../components/CreateUser';
import CreatePermission from "./CreatePermission";
import AssociateRoutePermission from "./AssociateRoutePermission";
import AssociateUserPermission from "./AssociateUserPermission";
import CreateUserRouteFunds from "./CreateUserRouteFunds";
import UpdateUser from "./UpdateUser";
import UsersTable from "./UserTable";
import RouteTable from "./RouteTable";
import StatisticsTable from "./StatisticsTable";
import ExternalStatisticsTable from "./ExternalUsageTable";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';


function AdminDashboard() {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const token = localStorage.getItem('token');
  const isTokenExpired = useAutoLogout(token);
  const showCreateUser = () => setCurrentView('createUser');
  const showDashboard = () => setCurrentView('dashboard');
  const showTextContent = () => setCurrentView('showText');
  const showCreatePermission = () => setCurrentView('createPermission')
  const showAssociationRoutePermission = () => setCurrentView('createAssociationRoutePermission')
  const showAssociationUserPermission = () => setCurrentView('createAssociationUserPermission')
  const [editingUserId, setEditingUserId] = useState(null);
  const showCreateUserRouteFunds = () => setCurrentView('createUserRouteFunds')
  const [createDropdownOpen, setCreateDropdownOpen] = useState(false);
  const [associateDropdownOpen, setAssociateDropdownOpen] = useState(false);

  const toggleCreateDropdown = () => setCreateDropdownOpen(!createDropdownOpen);
  const toggleAssociateDropdown = () => setAssociateDropdownOpen(!associateDropdownOpen);

  const handleEditUser = (userId) => {
    setEditingUserId(userId);
    setCurrentView('updateUser'); // Switch to the UpdateUser view
  };

  const handleEditPermissions = (userId) => {
    setEditingUserId(userId);
    setCurrentView('editPermissions'); // Set view to edit permissions
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin/login');
  };

  // Render the appropriate view based on the currentView state
  let view;
  switch (currentView) {
    case 'dashboard':
      view = (
          <>
            <div className="flex flex-row justify-between">
              <UsersTable onEditUser={handleEditUser} onEditPermissions={handleEditPermissions} />
            </div>
            <div className="my-4 flex flex-row justify-center">
              <RouteTable/>
            </div>
            <div className="my-4 flex flex-row justify-center">
              <StatisticsTable/>
            </div>
            <div className="my-4 flex flex-row justify-center">
              <ExternalStatisticsTable/>
            </div>
          </>
        );
    break;
    case 'updateUser':
      if (editingUserId) {
        view = <UpdateUser userId={editingUserId} />;
      } else {
        view = <div>Please select a user to edit.</div>;
      }
    break;
    case 'showText':
      view = <div>Some new text content here...</div>; // Replace with your actual text content
    break;
    case 'createUser':
        view = <CreateUser/>;
    break;
    case 'createPermission':
      view = <CreatePermission/>
    break;
    case 'createAssociationRoutePermission':
      view = <AssociateRoutePermission/>
    break;
    case 'createAssociationUserPermission':
      view = <AssociateUserPermission/>
    break;
    case 'createUserRouteFunds':
      view = <CreateUserRouteFunds/>
    break;

    default:
      view = (
        <>
          {isTokenExpired && (
            <div className="bg-red-600 text-white p-3 rounded">
              Your session has expired. Please log in again.
            </div>
          )}
        </>
      );
      break;
  }

  return (
    <div className="flex bg-gray-800 h-screen text-white">
      {/* Sidebar */}
      <aside className={`transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-black fixed inset-y-0 left-0 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0`}>
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex items-center justify-between p-4">
              <span className="text-lg font-semibold bg-clip-text">CoreAPI.Store</span>
              <button onClick={() => setSidebarOpen(false)} className="rounded-lg lg:hidden">
                <XIcon className="w-6 h-6 text-white"/>
              </button>
            </div>
            <nav className="m-4 space-y-2 mt-5">
              <button onClick={showDashboard} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                Main
              </button>

              {/* Create Dropdown */}
              <div className={`relative ${createDropdownOpen ? 'mb-12' : ''}`}>
                <button onClick={toggleCreateDropdown} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800 flex justify-between items-center">
                  Create
                  {createDropdownOpen ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
                {/* Dropdown Items */}
                {createDropdownOpen && (
                  <div className="absolute left-0 mt-2 py-2 w-full bg-black border border-gray-800 rounded-md shadow-xl z-10">
                    <button onClick={showCreateUser} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                      New User
                    </button>
                    <button onClick={showCreatePermission} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                      New Permission
                    </button>
                    <button onClick={showCreateUserRouteFunds} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                        New User Route Funds
                    </button>
                  </div>
                )}
              </div>

              {/* Association Dropdown */}
              <div className={`relative ${associateDropdownOpen ? 'mb-12' : ''}`}>
                <button onClick={toggleAssociateDropdown} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800 flex justify-between items-center">
                  Association
                  {associateDropdownOpen ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
                {/* Dropdown Items */}
                {associateDropdownOpen && (
                  <div className="absolute left-0 mt-2 py-2 w-full bg-black border border-gray-800 rounded-md shadow-xl z-10">
                    <button onClick={showAssociationRoutePermission} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                      Associate Route Permission
                    </button>
                    <button onClick={showAssociationUserPermission} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                      Associate User Permission
                    </button>
                  </div>
                )}
              </div>
              <button onClick={showTextContent} className="block w-full text-left px-4 py-2 rounded hover:bg-gray-800">
                Show Text Content
              </button>
              {/* Other navigation links can be added here */}
            </nav>
          </div>
          <button onClick={handleLogout} className="text-center font-bold mx-2 my-4 px-4 py-4 mt-auto rounded-full hover:bg-red-700">
            Logout
          </button>
        </div>
      </aside>

      {/* Content area */}
      <div className="flex-1 flex flex-col">
        <header className="px-4 py-3 bg-gray-800 flex justify-between items-center">
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-gray-500 focus:outline-none lg:hidden">
            <MenuIcon className="w-6 h-6"/>
          </button>
          <h1 className="text-xl font-bold">Admin Dashboard</h1>
        </header>

        {/* Main content */}
        <main className="flex-1 overflow-y-auto p-4">
          {isTokenExpired && (
            <div className="bg-red-600 text-white p-3 rounded">
              Your session has expired. Please log in again.
            </div>
          )}
          {view}
          {/* Additional content here */}
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;
