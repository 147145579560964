// src/components/CreateUserRouteFunds.js
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { useNavigate } from "react-router-dom";
import { useAutoLogout } from "../utils/useAutoLogout";
import {XIcon} from "@heroicons/react/solid";

function CreateUserRouteFunds() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  useAutoLogout(token, navigate);
  const [username, setUsername] = useState('');
  const [route, setRoute] = useState('');
  const [error, setError] = useState('');
  const [funds, setFunds] = useState('');
  const [success, setSuccess] = useState('');
  const clearSuccess = () => setSuccess('');
  const clearError = () => setError('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`${API_URL}/admin/create/user_route_funds`, { username, route, funds }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuccess(response.data.message);
      setUsername('');
      setRoute('');
      setFunds('');
    } catch (error) {
      setError(error.response?.data?.detail || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-gray-900 p-8 border border-gray-600 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-white mb-6">Create New User Route Funds</h2>
        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            {success}
            <button onClick={clearSuccess} className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <XIcon className="w-5 h-5 text-green-500" />
            </button>
          </div>
        )}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            {error}
            <button onClick={clearError} className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <XIcon className="w-5 h-5 text-red-500" />
            </button>
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6 text-black">
          <div className="form-group">
            <label htmlFor="username" className="text-sm font-medium text-white block mb-2">Username:</label>
            <input
              type="text"
              name="username"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              className="w-full p-2 border border-gray-600 rounded"
            />
          </div>
          <div className="form-group">
            <label htmlFor="route" className="text-sm font-medium text-white block mb-2">Route:</label>
            <input
              type="text"
              name="route"
              id="route"
              value={route}
              onChange={e => setRoute(e.target.value)}
              className="w-full p-2 border border-gray-600 rounded"
            />
          </div>
          <div className="form-group">
            <label htmlFor="funds" className="text-sm font-medium text-white block mb-2">Funds:</label>
            <input
              type="text"
              name="funds"
              id="funds"
              value={funds}
              onChange={e => setFunds(e.target.value)}
              className="w-full p-2 border border-gray-600 rounded"
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Create
          </button>
        </form>
      </div>
    );
}

export default CreateUserRouteFunds;
