// src/hooks/useAutoLogout.js
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const useAutoLogout = (token) => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    let logoutTimer;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        setIsTokenExpired(true);
        logoutTimer = setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/admin/login');
        }, 3000); // 3 seconds delay
      } else {
        logoutTimer = setTimeout(() => {
          localStorage.removeItem('token');
          setIsTokenExpired(true);
          navigate('/admin/login');
        }, (decodedToken.exp - currentTime) * 1000);
      }
    }
    return () => clearTimeout(logoutTimer);
  }, [token, navigate]);

  return isTokenExpired;
};

export const useAutoLogoutManager = (token) => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    let logoutTimer;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        setIsTokenExpired(true);
        logoutTimer = setTimeout(() => {
          localStorage.removeItem('tokenManager');
          navigate('/manager/login');
        }, 3000); // 3 seconds delay
      } else {
        logoutTimer = setTimeout(() => {
          localStorage.removeItem('tokenManager');
          setIsTokenExpired(true);
          navigate('/manager/login');
        }, (decodedToken.exp - currentTime) * 1000);
      }
    }
    return () => clearTimeout(logoutTimer);
  }, [token, navigate]);

  return isTokenExpired;
};
