// src/components/UserTableManager.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';


function UsersTableManager() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/manager/read/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenManager')}`,
          },
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="container mx-auto p-4 rounded-xl border border-gray-800 bg-black">
      <h1 className="text-2xl font-bold text-center text-white mb-6 border border-gray-700 rounded-full">All clients</h1>
      {loading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal table-auto bg-black border-collapse">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Username
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white0 uppercase tracking-wider">
                  Active
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Permissions
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Funds
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.username}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.is_active ? (
                        <span className="inline-block bg-green-500 rounded-full px-3 py-1 text-sm font-semibold text-black mr-2">
                          Yes
                        </span>) :
                      (
                        <span className="inline-block bg-red-500 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2">
                          No
                        </span>
                    )}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.permissions.map((permission) => (
                      <span key={permission.id} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                        {permission.name}
                      </span>
                    ))}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.routes_funds && user.routes_funds.length > 0 ? (
                      user.routes_funds.map((fund) => (
                        <div key={fund.route_name}>
                          {fund.route_name}: {fund.funds}
                        </div>
                      ))
                    ) : (
                      <span className="text-gray-500">No Funds</span> // Default message if no funds are present
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UsersTableManager;