// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import ProtectedRouteAdmin from './components/ProtectedRouteAdmin';

import ManagerLogin from "./components/ManagerLogin";
import ManagerDashboard from "./components/ManagerDashboard";
import ProtectedRouteManager from "./components/ProtectedRouteManager";

import Tech from './components/Tech';
import MagicSphere from "./components/MagicSphere";
import NotFound from './components/NotFound';
import StatusPage from "./components/StatusPage";

function App() {
  return (
    <Router>
      <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/manager/login" element={<ManagerLogin />} />
          <Route path="/status" element={<StatusPage />} />
          <Route
          path="/admin/dashboard"
          element={
            <ProtectedRouteAdmin>
              <AdminDashboard />
            </ProtectedRouteAdmin>
          }
          />
          <Route
          path="/manager/dashboard"
          element={
            <ProtectedRouteManager>
              <ManagerDashboard />
            </ProtectedRouteManager>
          }
          />
          <Route path="/tech" element={<Tech />} />
          <Route path="/" element={<MagicSphere />} />
      </Routes>
    </Router>
  );
}

export default App;
